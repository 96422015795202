import React from "react";
import { Page } from "../components/page";
import { Flex } from "../components/shared/flex";
import styled from "styled-components";
import { Spacer } from "../components/shared/spacer";

const Container = styled(Flex)`
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h2`
  line-height: 2em;
`;
const Text = styled.span`
  line-height: 2em;
`;

const BackLink = styled.a`
  position: absolute;
  top: 20px;
  right: 20px;
  text-decoration: none;
  color: #007bff; /* Or any other color you prefer */
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const PrivacyPolicy = () => {
  return (
    <Page>
     <BackLink href="/*">Go back to the home page</BackLink>
      <Spacer height={"20px"} />
      <Container>
        <Header>Privacy Policy</Header>
        <Text>
          <h1>Nomo Chat Privacy Policy</h1>
          <p><strong>Last Updated:</strong> Oct 16, 2023</p>

          <h2>Introduction</h2>
          <p>Thank you for choosing Nomo. Your privacy is paramount to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use the Nomo Chat app.</p>
          <p>For any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:nomochathelp@gmail.com">nomochathelp@gmail.com</a>.</p>

          <h2>1. Information We Collect</h2>
          <ul>
            <li><strong>Account Information:</strong> Includes your username, password, phone number, and other information you provide when you register.</li>
            <li><strong>Profile Information:</strong> Includes your profile picture, bio, posts, likes, followers, and other information you add to your profile.</li>
            <li><strong>Communication Data:</strong> Messages, comments, and other communications made within the app.</li>
            <li><strong>Log and Usage Data:</strong> Information about how you access and use the app, including IP address, device information, and interaction data.</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <ul>
            <li><strong>To Operate the App:</strong> Facilitate account creation, post content, and enable communication.</li>
            <li><strong>To Improve User Experience:</strong> Analyze usage to enhance app features and functionality.</li>
            <li><strong>For Safety and Security:</strong> Detect and prevent fraud, spam, and other security risks.</li>
          </ul>

          <h2>3. Sharing and Disclosure</h2>
          <p>We will not share your personal information with third parties without your consent, except:</p>
          <ul>
            <li>As required by law or legal processes.</li>
            <li>To enforce our terms of service.</li>
            <li>To protect the rights and safety of our users or third parties.</li>
          </ul>

          <h2>4. Children’s Privacy</h2>
          <p>Nomo Chat is intended for users over the age of 13. We do not knowingly collect personal information from children under the specified age. If we become aware of such collection, we will take steps to delete such information.</p>

          <h2>5. Security</h2>
          <p>We implement a variety of security measures to protect your information. However, no method of transmission or storage is 100% secure, and we cannot guarantee its absolute security.</p>

          <h2>6. Your Choices</h2>
          <ul>
            <li><strong>Account Information:</strong> You can review, change, or terminate your account at any time.</li>
          </ul>

          <h2>7. Updates to this Policy</h2>
          <p>We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated revision date.</p>

          <h2>8. Contact Us</h2>
          <p>For any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:nomochathelp@gmail.com">nomochathelp@gmail.com</a>.</p>
        </Text>
      </Container>
    </Page>
  );
};
