import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { store } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);

if (module.hot) {
  module.hot.accept("./app", () => {
    root.render(
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    );
  });
}
