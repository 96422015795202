import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SocketState {
  isEstablishingConnection: boolean;
  isConnected: boolean;
  joined: boolean;
  joining: boolean;
  user?: {
    id: string;
    name: string;
  };
  roomId: string;
  status: boolean;
}

const initialState: SocketState = {
  isEstablishingConnection: false,
  isConnected: false,
  joined: false,
  roomId: "",
  status: false,
  joining: true,
};

const name = "socket";

const socketSlice = createSlice({
  name,
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      console.log("socket connection established");
      state.isConnected = true;
      state.isEstablishingConnection = false;
    },
    setRoomId: (state, action: PayloadAction<string>) => {
      state.roomId = action.payload;
      return state;
    },
    joinRoom: (state, _: PayloadAction<string>) => {
      return state;
    },
    setJoined: (state, action: PayloadAction<boolean>) => {
      state.joined = action.payload;
      return state;
    },
    setJoining: (state, action: PayloadAction<boolean>) => {
      state.joining = action.payload;
      return state;
    },
    sendText: (state, _: PayloadAction<string>) => {
      // defining action parameter here for type-safety, however, this reducer gets matched in the middleware.
      return state;
    },
    sendLastText: (state, _: PayloadAction<string>) => {
      // defining action parameter here for type-safety, however, this reducer gets matched in the middleware.
      return state;
    },
    sendStatus: (state, _: PayloadAction<boolean>) => {
      return state;
    },

    setStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
      return state;
    },
  },
});

export const socketActions = socketSlice.actions;

export default socketSlice.reducer;
