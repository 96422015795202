import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
`;

export const Col = styled(Flex)`
  flex-direction: column;
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;

export const CC = styled(Col)`
  justify-content: center;
`;

export const H2 = styled.h2`
  text-align: center;
`;

export const Header = styled(Flex)`
  font-size: 42px;
  font-weight: 800;
`;
