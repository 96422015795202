import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// name
const name = "texts";

export type TextsState = {
  receivedText: string;
  receivedLastText: string;
};

const initialState: TextsState = {
  receivedText: "",
  receivedLastText: "",
};

export const textsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setReceivedText(state, action: PayloadAction<string>) {
      state.receivedText = action.payload;
    },
    setReceivedLastText(state, action: PayloadAction<string>) {
      state.receivedLastText = action.payload;
    },
  },
});

export const textsActions = textsSlice.actions;

export default textsSlice.reducer;
