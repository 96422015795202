import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import socketMiddleware from "./models/socket/socketMiddleware";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(socketMiddleware),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

