import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { Flex } from "./shared/flex";

const PageContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const Page = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const pageRef = useRef<HTMLDivElement>(document.createElement("div"));

  useLayoutEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollTop = 0;
    }
  });

  return <PageContainer>{children}</PageContainer>;
};
