import { Middleware, ThunkDispatch } from "@reduxjs/toolkit";
import { io, Socket } from "socket.io-client";
import { socketActions } from "./socket";
import type { RootState } from "../../store";
import { textsActions } from "../texts";

export enum SocketEvent {
  ReceiveText = "receive_text",
  ReceiveLastText = "receive_last_text",
  SendText = "send_text",
  SendLastText = "send_last_text",
  JoinRoom = "join_room",
  SendStatus = "send_status",
  ReceiveStatus = "receive_status",
}

enum SocketResponses {
  roomFull = "room_full",
  roomJoined = "room_joined",
}

interface ClientToServerEvents {
  [SocketEvent.SendText]: (payload: { text: string; roomId: string }) => void;
  [SocketEvent.SendLastText]: (payload: {
    text: string;
    roomId: string;
  }) => void;
  [SocketEvent.JoinRoom]: (
    payload: string,
    callback: (response: {
      roomStatus: SocketResponses;
      roomId: string;
    }) => void
  ) => void;
  [SocketEvent.SendStatus]: (payload: {
    status: boolean;
    roomId: string;
  }) => void;
}

interface ServerToClientEvents {
  [SocketEvent.ReceiveText]: (payload: string) => void;
  [SocketEvent.ReceiveLastText]: (payload: string) => void;
  [SocketEvent.ReceiveStatus]: (payload: boolean) => void;
}

const socketMiddleware: Middleware<
  {},
  RootState,
  // Third arg here might wanna be AsyncThunkAction
  ThunkDispatch<RootState, any, any>
> = (store) => {
  // the generic type orders are reversed server side
  let socket: Socket<ServerToClientEvents, ClientToServerEvents>;
  return (next) => (action) => {
    const isConnectionEstablished =
      socket && store.getState().socket.isConnected;

    if (socketActions.startConnecting.match(action)) {
      socket = io(process.env.REACT_APP_BACKEND_URL as string, {
        withCredentials: true,
        transports: ["websocket"],
      });

      socket.on("connect", () => {
        store.dispatch(socketActions.connectionEstablished());
      });

      socket.on(SocketEvent.ReceiveText, (payload: string) => {
        store.dispatch(textsActions.setReceivedText(payload));
      });

      socket.on(SocketEvent.ReceiveLastText, (payload: string) => {
        store.dispatch(textsActions.setReceivedLastText(payload));
      });

      socket.on(SocketEvent.ReceiveStatus, (payload: boolean) => {
        store.dispatch(socketActions.setStatus(payload));
      });
    }

    if (socketActions.joinRoom.match(action) && isConnectionEstablished) {
      socket.emit(SocketEvent.JoinRoom, action.payload, (response) => {
        if (response.roomStatus === SocketResponses.roomFull) {
          store.dispatch(socketActions.setJoining(false));
          return store.dispatch(socketActions.setJoined(false));
        }
        store.dispatch(socketActions.setJoining(false));
        store.dispatch(socketActions.setRoomId(response.roomId));
      });
    }

    if (socketActions.sendText.match(action) && isConnectionEstablished) {
      socket.emit(SocketEvent.SendText, {
        text: action.payload,
        roomId: store.getState().socket.roomId,
      });
    }

    if (socketActions.sendLastText.match(action) && isConnectionEstablished) {
      socket.emit(SocketEvent.SendLastText, {
        text: action.payload,
        roomId: store.getState().socket.roomId,
      });
    }

    if (socketActions.sendStatus.match(action) && isConnectionEstablished) {
      socket.emit(SocketEvent.SendStatus, {
        status: action.payload,
        roomId: store.getState().socket.roomId,
      });
    }

    next(action);
  };
};

export default socketMiddleware;
