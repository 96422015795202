import React from "react";
import { Page } from "../components/page";

import { Flex } from "../components/shared/flex";
import styled from "styled-components";
import { LandingPage } from "./landingPage";

const Container = styled(Flex)`
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Main = () => {
  return (
    <Page>
      <Container>
        <LandingPage />
      </Container>
    </Page>
  );
};
