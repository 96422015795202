import React from "react";
import { Link } from "react-router-dom";

export const LandingPage = () => {
  return (
    <>
      <h1>Want to be part of Nomo?</h1>
      <p>Nothing lasts forever</p>
      <p>
        <a 
           href="https://apps.apple.com/us/app/nomo-nothing-lasts-forever/id1666076551"
           target="_blank" 
           rel="noopener noreferrer"
        >
          Download iOS App.
        </a>
      </p>
      <br></br>
      <br></br>
      <br></br>

      <Link to="/info/privacy-policy">Privacy Policy</Link>
      <div style={{ height: "20px" }} />
      <Link to="/info/eula">Terms of Service</Link>
    </>
  );
};


