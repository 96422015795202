import { DefaultTheme } from "styled-components";
import { colors } from "./colors";

export const theme: DefaultTheme = {
  dark: false,
  colors: {
    primary: "rgb(255, 45, 85)",
    background: colors.white,
    card: "rgb(255, 255, 255)",
    text: colors.black,
    border: "rgb(199, 199, 204)",
    notification: "rgb(255, 69, 58)",
    myFrame: colors.gray,
    myFrameActive: colors.darkGray,
    yourFrame: colors.lightGray,
    active: colors.pink,
    inactive: colors.darkGray,
  },
};
