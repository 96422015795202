import React from "react";
import { Flex } from "./flex";

export const Spacer = ({
  height = 10,
  width = "100%",
}: {
  height?: number | string;
  width?: number | string;
}) => {
  return <Flex style={{ height, width }} />;
};
