import React from "react";
import { Page } from "../components/page";
import { Col, Flex } from "../components/shared/flex";
import styled from "styled-components";
import { Spacer } from "../components/shared/spacer";

const Container = styled(Flex)`
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h2`
  line-height: 2em;
`;

const SubHeader = styled.h4``;
const Text = styled.span`
  line-height: 1.5em;
`;

export const SupportPage = () => {
  return (
    <Page>
      <Spacer height={"20px"} />
      <Container>
        <Header>Support</Header>
        <Text>
          {`Welcome to the support page for Nomo. Here you will find answers to commonly asked questions, troubleshooting tips, and resources to help you make the most of our app.
        `}{" "}
        </Text>
        <Spacer height={"2em"} />

        <SubHeader>{`Frequently Asked Questions:`}</SubHeader>
        <Col>
          <Text>{`Q: Does Nomo save any of my chats?`}</Text>
          <Text>{`A: No, Nomo is an ephemeral chatting platform. All texting exchanges are non-permanent and not persisted.`}</Text>
        </Col>
        <Spacer height={"2em"} />
        <Col>
          <Text>{`Q: Can I retrieve a deleted chat?`}</Text>
          <Text>{`A: No, once a chat is deleted it cannot be retrieved. Nomo is not recommended as a communication tool for anything that requires records being kept.`}</Text>
        </Col>
        <Spacer height={"2em"} />
        <Col>
          <Text>{`Q: How long do chats stay in the application?`}</Text>
          <Text>{`A: Chats are not persisted anywhere and are deleted a few seconds after they are typed and do not stay in the application for any length of time.`}</Text>
        </Col>
        <Spacer height={"2em"} />
        <Col>
          <Text>{`Q: Is there a way to keep the chats for longer?`}</Text>
          <Text>{`A: No, the purpose of our application is to provide ephemeral, private conversations. We do not have the capability to save or keep chats for any length of time.`}</Text>
        </Col>
        <Spacer height={"2em"} />
        <Col>
          <Text>{`Q: Can I use this application for group chats?`}</Text>
          <Text>{`A: No, our application is designed for one-on-one conversations only.`}</Text>
        </Col>
        <Spacer height={"2em"} />
        <SubHeader>{`Troubleshooting:`}</SubHeader>
        <Col>
          <Text>{`If you are having trouble getting into a chat, try unplugging and turning it back on`}</Text>
          <Spacer height={"2em"} />
          <Text>{`If you are experiencing issues with the app, try clearing your browser's cache and cookies, and then reload the page.`}</Text>
          <Spacer height={"2em"} />
          <Text>{`Please be aware that Nomo is in Beta stage testing and may be unstable / unreliable.`}</Text>
          <Spacer height={"2em"} />
          <Text>{`If the problem persists, please contact us at support@nomo.chat`}</Text>
        </Col>
      </Container>
    </Page>
  );
};
