import React from "react";
import { Page } from "../components/page";
import { Flex } from "../components/shared/flex";
import styled from "styled-components";
import { Spacer } from "../components/shared/spacer";

const Container = styled(Flex)`
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h2`
  line-height: 2em;
`;

const SubHeader = styled.h4``;
const Text = styled.span`
  line-height: 1.5em;
`;

export const EndUserAgreementPage = () => {
  return (
    <Page>
      <Spacer height={"20px"} />
      <Container>
        <Header>End-User License Agreement for Nomo</Header>
        <Text>
          This End-User License Agreement ("EULA") is a legal agreement between
          you (either an individual or a single entity) and NomoTech(the
          "Developer") for the use of "Nomo. Nothing lasts forever" (the "App").
        </Text>
        <Spacer height={"1em"} />
        <Text>
          By installing, copying, or otherwise using the App, you agree to be
          bound by the terms of this EULA. If you do not agree to the terms of
          this EULA, do not install or use the App.
        </Text>
        <Spacer height={"2em"} />

        <SubHeader>{`1. Grant of License`}</SubHeader>
        <Text>{`The Developer grants you a limited, non-exclusive, non-transferable, revocable license to use the App solely for your personal, non-commercial use. You may not rent, lease, lend, sell, redistribute or sublicense the App. You may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the App, any updates, or any part thereof.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`2. Restrictions`}</SubHeader>
        <Text>{`You may not use the App for any illegal or unauthorized purpose, or in any manner that could damage, disable, overburden, or impair the App or the Developer's servers or networks. You may not use the App to gain unauthorized access to any service or data. You may not modify, decompile, or reverse-engineer the app.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`3. Content Guidelines`}</SubHeader>
        <Text>{`While using the App, you are part of a trusted community. To ensure that every member feels safe, respected, and can enjoy meaningful connections, you agree to adhere to the following guidelines:`}</Text>
        <ol type="a">
          <li>
            Respect and Privacy: Always treat fellow users with respect. Avoid
            posting content that intrudes on others' privacy, including, but not
            limited to, sharing private conversations, personal data, or photos
            without consent.
          </li>
          <br />
          <li>
            No Hate Speech or Discrimination: Any content that promotes hatred,
            violence, or discrimination against individuals based on race,
            gender, religion, nationality, disability, sexual orientation, or
            age is strictly prohibited.
          </li>
          <br />
          <li>
            Avoid Abusive or Harmful Content: Content that harasses, bullies, or
            threatens others is not tolerated. Similarly, do not post content
            that promotes self-harm, suicidal thoughts, or any form of abuse.
          </li>
          <br />
          <li>
            No Obscene or Graphic Content: Graphic violence or adult content,
            including but not limited to sexual content and graphic imagery, is
            not permitted.
          </li>
          <br />
          <li>
            No Misinformation: Do not knowingly share false information or use
            the platform to spread rumors, conspiracy theories, or deceit.
          </li>
          <br />
          <li>
            Respect Copyright and Trademark Laws: Only share or distribute
            copyrighted or trademarked content if you have proper authorization
            or rights to do so.
          </li>
          <br />
          <li>
            No Promotion of Harmful Activities: Content that promotes illegal
            activities, harm to others, or represents a risk to public safety
            will be removed.
          </li>
          <br />
          <li>
            Respect Community Decisions: Content flagged by the community or the
            App as inappropriate or offensive might be reviewed and removed.
            Repeated violations can result in temporary suspension or permanent
            account termination.
          </li>
          <br />
          <li>
            Reporting and Accountability: If you come across content or behavior
            that violates these guidelines, please report it. We rely on our
            community to uphold these standards and keep the App a safe space
            for genuine connections.
          </li>
        </ol>
        <br />

        <SubHeader>{`4. Promoting Safety, Security, and Integrity of the App`}</SubHeader>
        <Text>{`Building a thriving community on the App is rooted in the safety and security of our users. We are committed to preserving the integrity of our platform and safeguarding our users against harmful conduct.`}</Text>
        <ol type="1">
          <li>
            Proactive Monitoring: We have instituted dedicated teams and
            advanced technical systems globally to detect potential misuse of
            our services and harmful behavior. Our goal is to preemptively
            address risks before they escalate.
          </li>
          <br />
          <li>
            Responding to Reports: User reports are invaluable to us. When you
            flag potentially violating content or conduct, it enables us to take
            swift action. Actions may include: notifying the involved parties,
            offering assistance, content removal, restricting access to certain
            features, disabling accounts, or even liaising with law enforcement
            when necessary.
          </li>
          <br />
          <li>
            Data Sharing for Safety: We might share data within our associated
            entities when we detect harmful conduct on the App. This
            cross-sharing aims to bolster the safety, security, and integrity
            across our services. For instance, collaborating with entities
            providing financial services helps us ensure transactions are
            legitimate and secure.
          </li>
          <br />
          <li>
            Lawful Access and Preservation: If required or permitted by law, The
            Developer may access, preserve, utilize, and share any information
            collected about users. Our actions will always be grounded in good
            faith, prioritizing user safety and adherence to legal stipulations.
          </li>
          <br />
          <li>
            Review Our Privacy Policy: For a comprehensive understanding of how
            we handle your data and uphold safety, please review our Privacy
            Policy.
          </li>
          <br />
        </ol>

        <Spacer height={"2em"} />

        <SubHeader>{`5. Termination`}</SubHeader>
        <Text>{`This EULA is effective until terminated. Your rights under this EULA will terminate automatically without notice from the Developer if you fail to comply with any term(s) of this EULA. Upon termination of this EULA, you shall cease all use of the App and destroy all copies, full or partial, of the App in your possession.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`6. Disclaimer of Warranties`}</SubHeader>
        <Text>{`The App is provided on an "AS IS" and "AS AVAILABLE" basis. The Developer makes no warranty that the App will meet your requirements or that the operation of the App will be uninterrupted or error-free.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`7. Limitation of Liability`}</SubHeader>
        <Text>{`To the maximum extent permitted by applicable law, in no event shall the Developer be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of the App.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`8. Amendments`}</SubHeader>
        <Text>{`The Developer reserves the right to modify, suspend, or discontinue, temporarily or permanently, the App or any service to which it connects, with or without notice and without liability to you.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`9. Governing Law`}</SubHeader>
        <Text>{`This EULA shall be governed by and construed in accordance with the laws of the United States, without giving effect to any principles of conflict of law.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`10. Entire Agreement`}</SubHeader>
        <Text>{`This EULA constitutes the entire agreement between you and the Developer regarding the use of the App. Any amendments to this EULA must be made in writing and signed by both parties.`}</Text>
        <Spacer height={"2em"} />

        <SubHeader>{`11. Contact Information`}</SubHeader>
        <Text>
          {`If you have any questions about this EULA, please contact `}
          <a href="mailto:nomochathelp@gmail.com">nomochatHelp@gmail.com</a>
        </Text>
        <Spacer height={"20em"} />
      </Container>
    </Page>
  );
};
