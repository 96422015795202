import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./styles/global.styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Main } from "./containers/main";
import { theme } from "./styles/theme";
import { PrivacyPolicy } from "./containers/privacyPolicy";
import { SupportPage } from "./containers/supportPage";
import { EndUserAgreementPage } from "./containers/eulaPage";

const App = () => {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/info/eula" element={<EndUserAgreementPage />} />
            <Route path="/info/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/info/support" element={<SupportPage />} />
            <Route path="/*" element={<Main />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
